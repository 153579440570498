/*----------------------------------------------
    # service style
----------------------------------------------*/
.single-service-inner {
  background: #fff;
  padding: 40px 35px;
  margin-bottom: 30px;
  border-radius: 24px;
  transition: 0.5s;
  box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.06);
  .thumb {
    margin-bottom: 35px;
    transition: 0.5s;
    img {
      transition: 0.5s;
    }
  }
  .details {
    h5 {
      margin-bottom: 20px;
      position: relative;
      transition: 0.4s;
    }
    p {
      margin-bottom: 28px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 20px rgba(19, 16, 34, 0.1);
    .thumb {
      img {
        transform: rotateY(180deg);
      }
    }
  }
  &.style-3 {
    border-radius: 5px;
    margin-top: 50px;
    padding: 35px;
    .thumb {
      height: 100px;
      width: 100px;
      line-height: 96px;
      border-radius: 50%;
      border: 1px solid #e3e3e3;
      text-align: center;
      margin-top: -82px;
      background: #fff;
    }
    .details {
      h5 {
        font-size: 22px;
      }
    }
    &:hover {
      .thumb {
        border: 1px solid var(--main-color);
      }
    }
  }
}

.single-service-inner-2 {
  margin-bottom: 30px;
  .thumb {
    background: #f3f4ff;
    border-radius: 20px;
    padding: 30px;
    img {
      transform: scale(1.05);
      transition: 0.6s;
    }
  }
  .details {
    padding: 30px;
    background: #fff;
    margin-top: -100px;
    margin-left: 60px;
    position: relative;
    z-index: 2;
    border-radius: 40px 0 0 0;
    .icon {
      img {
        transition: 0.4s;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &:hover {
    .thumb {
      img {
        transform: scale(1.1);
      }
    }
    .details {
      box-shadow: $box-shadow;
      .icon {
        img {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.single-service-inner-3 {
  background: #fff;
  box-shadow: $box-shadow;
  position: relative;
  transition: 0.4s;
  margin-bottom: 30px;
  .thumb {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #50af9b;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.4s;
    .thumb-inner {
      background: #fff;
      height: 70px;
      width: 70px;
      line-height: 68px;
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(19, 16, 34, 0.15);
      margin: 7px;
    }
  }
  .details {
    p {
      transition: 0.4s;
    }
  }
  &.single-service-inner-3-left {
    padding: 40px 50px 40px 30px;
    .thumb {
      right: -40px;
    }
  }
  &.single-service-inner-3-right {
    padding: 40px 30px 40px 50px;
    .thumb {
      left: -40px;
    }
  }
  &:hover {
    background: #50af9b;
    .thumb {
      border: 1px solid #fff;
    }
    .details {
      h5 {
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
  }
}
.bg-blue-right {
  background: rgba(80, 175, 155, 0.1);
}
.service-middle-section {
  padding: 30px 30px 0 30px;
  height: 100%;
  display: flex;
  align-items: end;
}

.ZSollink:hover {
  color: var(--heading-color);
}
.ZSollinkWork:hover {
  color: var(--main-color5);
}